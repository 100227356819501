import React from "react"
import { Link } from "gatsby"
import Logo from '../images/logo.png';
export default function Nav() {
    return (
        <React.Fragment>
            <div className="container text-left" id="logo">
                <Link to="/"><img src={Logo} alt="" title="" /></Link>
            </div>
        </React.Fragment>
    )
}
