import React from "react";
import SiteLogo from './sitelogo';
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/style.css';

export default function NonLayout({ children }) {
  return (
    <React.Fragment>
      <div className="site-logo non-layout">
        <SiteLogo> </SiteLogo>
      </div>
      {children}
    </React.Fragment>
  )
}
